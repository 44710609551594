/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Shanti&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {

   //  font-family: 'Shanti', sans-serif;
   font-family: 'Open Sans', sans-serif;
}

// @font-face {
//     font-family: 'Shanti';
//     src: url('https://fonts.googleapis.com/css2?family=Shanti');
//     font-weight: 200;
//     font-style: normal;
//  }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('https://fonts.googleapis.com/css2?family=Open+Sans');
//     font-weight: 400;
//     font-style: normal;
//  }
 
.toolbarTitle {
    --color: #fff;
 }

 ion-back-button {
    --color: #fff !important;
    --color-focused: #fff;
    --background: #00a6aa;
 }

 ion-loading.custom-loading {
    --background: #00a6aa;
    color: #fff;
    --spinner-color: #fff;
 }

 .alert-css {
    --background: #00a6aa;
    --color: #fff;
 }

 .alert-css .alert-message {
    --background: #00a6aa;
    color: #fff;
 }

 .alertButtons {
    color: #fff !important;
 }